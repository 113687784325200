import {Directive, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[multilineEllipsis]',
  standalone: false
})
export class MultilineEllipsisDirective implements OnInit, OnDestroy {
  private mutationObserver!: MutationObserver;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.mutationObserver = new MutationObserver(() => {
      this.checkEllipsis();
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });

    this.checkEllipsis();
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.checkEllipsis();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkEllipsis();
  }

  private checkEllipsis(): void {
    const element = this.elementRef.nativeElement;
    const lines = element.innerText.split('\n');
    const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
    const maxHeight = lineHeight * 2; // Adjust this for the number of lines you want to clamp

    if (element.scrollHeight > maxHeight) {
      element.title = element.textContent;
    } else {
      element.removeAttribute('title');
    }
  }
}
