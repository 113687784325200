import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OutsideClickDirective} from './outside-click.directive';
import {IsEllipsisDirective} from './is-ellipsis.directive';
import {StopPropagationDirective} from './stop-propagation.directive';
import {IsInViewDirective} from './is-in-view.directive';
import {MultilineEllipsisDirective} from './multiline-ellipsis.directive';

@NgModule({
  declarations: [
    OutsideClickDirective,
    IsEllipsisDirective,
    StopPropagationDirective,
    IsInViewDirective,
    MultilineEllipsisDirective
  ],
  imports: [CommonModule],
  exports: [
    OutsideClickDirective,
    IsEllipsisDirective,
    StopPropagationDirective,
    IsInViewDirective,
    MultilineEllipsisDirective
  ]
})
export class DirectiveModule {
}
