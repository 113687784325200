import {Directive, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[isEllipsis]',
  standalone: false
})
export class IsEllipsisDirective implements OnInit, OnDestroy {
  private mutationObserver!: MutationObserver;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.mutationObserver = new MutationObserver(() => {
      this.checkEllipsis();
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });

    this.checkEllipsis();
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.checkEllipsis();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkEllipsis();
  }

  private checkEllipsis(): void {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.title = element.textContent;
    } else if (element.title) {
      element.removeAttribute('title');
    }
  }
}
