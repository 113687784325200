import {Injectable, signal} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Injectable({
  providedIn: 'root'
})
export class MobileDeviceService {
  isMobileDevice = signal(false);
  isMaxTabletDevice = signal(false);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(['(max-width: 639px)', '(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        this.isMobileDevice.set(state.breakpoints['(max-width: 639px)']);
        this.isMaxTabletDevice.set(state.breakpoints['(max-width: 767px)']);
      });
  }
}
