import {Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import {MobileDeviceService} from '../services/mobile-device.service';

@Directive({
  selector: '[isInView]',
  standalone: false
})
export class IsInViewDirective implements OnDestroy {
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private mobileDeviceService: MobileDeviceService) {
    this.initiateObserver();
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private initiateObserver(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          requestAnimationFrame(() => {
            this.setAnimationClass(entry.boundingClientRect);
            this.observer.unobserve(entry.target); // Ensure the animation only occurs once
          });
        }
      });
    }, {
      rootMargin: this.mobileDeviceService.isMobileDevice() ? '80px 0px' : '40px 0px'
    });

    this.observer.observe(this.el.nativeElement);
  }

  private setAnimationClass(rect: DOMRectReadOnly) {
    const halfScreen = window.innerWidth / 2;

    if (rect.left < halfScreen) {
      this.renderer.addClass(this.el.nativeElement, 'zoom-in-right-blur');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'zoom-in-left-blur');
    }
  }
}
